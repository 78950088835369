import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAttributeContext } from '~/hooks/useAttributeContext';
import { setCardParams } from '~/redux/actions/conversationDataR';
import * as yup from '~/utils/yup';

import { AIErrorBlocksSchema } from '../utils/AIErrorBlocksSchema';

export const INTENT_ATTRIBUTE = 'intent' as const;
export const PROMPT =
  "You are a world-class intent classifier. Please classify the following user message '${_last_input}' into one of the 'Intents' based on its content. Output only the name of the 'Intent' that best matches the message. If none matches return the fallback intent below. Reply only with the intent name";

type ErrorAndAttributeConfig = AIErrorBlocksSchema & {
  outputAttributeName?: string;
};

type Params = {
  orderIndex: number;
};

type IntentDescriptionType = {
  intent: string;
  description: string;
};

const parseString = (str: string) =>
  str.split(',').map<IntentDescriptionType>((item) => {
    const [intent, description] = item.split(':');
    return { intent, description: description ?? '' };
  });

const stringiFy = (arr: IntentDescriptionType[]) =>
  arr
    .map(({ intent, description }) =>
      description ? `${intent}:${description}` : intent
    )
    .join(',');

export const useIntentAICard = ({ orderIndex }: Params) => {
  const dispatch = useDispatch();
  const { createAttribute, attributes } = useAttributeContext();

  const { data } = useSelector(({ conversationsDataR }) => ({
    data: conversationsDataR.intentCardsData[orderIndex],
  }));

  const intentAttributeExist = attributes.some(
    ({ cust_attr_name }) => cust_attr_name === INTENT_ATTRIBUTE
  );

  const errorAndAttributeConfig = React.useMemo<ErrorAndAttributeConfig>(() => {
    if (yup.string().isJson().isValidSync(data.param4)) {
      return AIErrorBlocksSchema.cast(data.param4, { assert: false });
    }

    return {
      outputAttributeName: data.param4 || INTENT_ATTRIBUTE,
    };
  }, [data.param4]);

  React.useEffect(() => {
    if (intentAttributeExist) return;

    const timeout: number = window.setTimeout(() => {
      createAttribute(INTENT_ATTRIBUTE);
    }, 200);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [intentAttributeExist, createAttribute]);

  React.useEffect(() => {
    const timeout: number = window.setTimeout(() => {
      if (!data.param2 || !data.param3) return;

      const intents = parseString(data.param2);

      const intentsPrompt = `\nThese are the Intents:\n- ${intents.map((i) => (i.description ? `'${i.intent}'(${i.description})` : `'${i.intent}'`)).join('\n- ')}`;

      const fallbackPrompt = `\nThis is the fallback:\n- ${data.param3}`;

      dispatch(
        setCardParams({
          cardIndex: orderIndex,
          param1: `${PROMPT}\n${intentsPrompt}\n${fallbackPrompt}`,
        })
      );
    }, 500);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [data.param2, data.param3, dispatch, orderIndex]);

  const handleAddIntent = () => {
    dispatch(
      setCardParams({
        cardIndex: orderIndex,
        param2: data.param2 ? `${data.param2},` : ',',
      })
    );
  };

  const handleDeleteIntent = (index: number) => {
    if (!data.param2) return;

    const intents = data.param2.split(',').filter((_, i) => i !== index);

    dispatch(
      setCardParams({
        cardIndex: orderIndex,
        param2: intents.join(','),
      })
    );
  };

  const handleIntentChange = (
    index: number,
    field: keyof IntentDescriptionType,
    value: string
  ) => {
    const fields = parseString(data.param2 ?? '');
    fields[index][field] = value;

    dispatch(
      setCardParams({
        cardIndex: orderIndex,
        param2: stringiFy(fields),
      })
    );
  };

  const handleFallbackIntentChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      setCardParams({
        cardIndex: orderIndex,
        param3: e.target.value,
      })
    );
  };

  const handleErrorBlockChange =
    (errorKey: keyof ErrorAndAttributeConfig) =>
    (blockId: number | undefined) => {
      dispatch(
        setCardParams({
          cardIndex: orderIndex,
          param4: JSON.stringify({
            ...errorAndAttributeConfig,
            [errorKey]: blockId,
          }),
        })
      );
    };

  const prompt = data.param1;
  const intents = parseString(data.param2 || ':');
  const fallbackIntent = data.param3 || '';

  return {
    errorAndAttributeConfig,
    handleAddIntent,
    handleDeleteIntent,
    handleErrorBlockChange,
    handleFallbackIntentChange,
    handleIntentChange,
    fallbackIntent,
    intents,
    prompt,
  };
};
