import { Grid, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { useSelectedChatbot } from '~/hooks/useSelectedChatbot';
import {
  getAllAgents,
  getDeployments,
} from '~/redux/actions/conversationDataR';

import { AgentListSkeleton } from './AgentListSkeleton';
import { AgentsListCard } from './AgentsListCard/AgentsListCard';
import { AgentsListCreateCard } from './AgentsListCreateCard/AgentsListCreateCard';
import { AgentsListFilter } from './AgentsListFilter/AgentsListFilter';
import { AgentsListTabs, AgentType } from './types';
import { useAgentsList } from './useAgentsList';

const useStyles = makeStyles(() => ({
  card: {
    padding: 10,
    textAlign: 'center',
  },
  container: {
    minHeight: '45vh',
  },
}));

type PropsType = {
  onSelectingChatbot: (agent?: AgentType) => void;
};

export const ConversationsListContainer = ({
  onSelectingChatbot,
}: PropsType) => {
  const isMounted = React.useRef(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [isPublic, setIsPublic] = React.useState<boolean | null>(null);
  const [activeTab, setActiveTab] = React.useState<AgentsListTabs>(
    AgentsListTabs.All
  );

  const { unsetSelectedChatbot, setSelectChatbot } = useSelectedChatbot();
  const { agents, AgentTemplateHasChildrenMap, filteredAgents } = useAgentsList(
    {
      activeTab,
      isPublic,
      searchText,
    }
  );

  React.useEffect(() => {
    if (isMounted.current) return;

    const fetchAllData = async () => {
      setIsLoading(true);

      try {
        await Promise.all([
          dispatch(getAllAgents()),
          dispatch(getDeployments()),
        ]);
      } finally {
        setIsLoading(false);
        isMounted.current = true;
      }
    };

    fetchAllData();
  }, [agents, dispatch]);

  const handleSelectAgent = (agent: AgentType) => {
    setSelectChatbot(agent);
    onSelectingChatbot(agent);
  };

  const handleUnselectAgent = () => {
    unsetSelectedChatbot();
    onSelectingChatbot();
  };

  const handleEnter = () => {
    if (filteredAgents.length === 1) {
      handleSelectAgent(filteredAgents[0]);
    }
  };

  const shouldShowAll = activeTab === AgentsListTabs.All;

  if (isLoading) {
    return <AgentListSkeleton />;
  }

  return (
    <>
      <AgentsListFilter
        currentTab={activeTab}
        isPublic={isPublic}
        searchText={searchText}
        onEnter={handleEnter}
        onChangeTab={setActiveTab}
        onChangePublic={setIsPublic}
        onChangeSearchText={setSearchText}
      />

      <Grid
        container
        spacing={4}
        className={classes.container}
        data-testid="agents-list-container"
      >
        {shouldShowAll && (
          <Grid item md={3} xs={12} data-testid="agents-list-card">
            <AgentsListCreateCard className={classes.card} />
          </Grid>
        )}
        {filteredAgents.map((agent) => (
          <Grid
            key={agent.id}
            item
            md={3}
            xs={12}
            data-testid="agents-list-card"
          >
            <AgentsListCard
              agent={agent}
              agentTemplateHasChildrenMap={AgentTemplateHasChildrenMap}
              className={classes.card}
              handleSelectAgent={handleSelectAgent}
              handleUnselectAgent={handleUnselectAgent}
            />
          </Grid>
        ))}

        {!shouldShowAll && agents.length === 0 && (
          <div style={{ position: 'relative', width: '100%' }}>
            <div className="fullBodyOverlay">No Chatbots Found</div>
          </div>
        )}
      </Grid>
    </>
  );
};
